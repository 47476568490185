import AuthenticationService from "./AuthenticationService";

const LabService = {

    getServerDomain : () => {
        let serverDomain = "https://lab-api.cosentios.com";
        if (window.location.hostname === "localhost") {
            serverDomain = "http://localhost:8080";
        }
        return serverDomain;
    },
    listServers : (successHandler, errorHandler) => {
        let serverDomain = LabService.getServerDomain();
        fetch(serverDomain + "/admin/list",
            {
                method: "GET",
                headers: {
                    "Content-Type" : "application/json",
                    "Accept": "*/*",
                    "Authorization" : AuthenticationService.getAuthorizationHeaderValue()
                },
                mode: "cors",
                credentials: "include",
                cache: "no-cache"
            }
        )
            .then(response => response.json())
            .then(data => successHandler(data))
            .catch(error => errorHandler(error));
    },
    stopStartServers : (stop, envResources) => {
        let action = stop ? "stop" : "start";
        let payload = {
            instanceIds : envResources.map(resource => resource.id)
        }

        let payloadString = JSON.stringify(payload);
        console.log("Payload string", payloadString)
        let serverDomain = LabService.getServerDomain();
        fetch(serverDomain + "/admin/" + action, {
            method: "POST",
            headers: {
                "Content-Type" : "application/json",
                "Accept": "*/*",
                "Authorization" : AuthenticationService.getAuthorizationHeaderValue()
            },
            mode: "cors",
            credentials: "include",
            cache: "no-cache",
            body: payloadString
        }).then(response => response.json())
            .then(data => console.log(data));

    },
    resizeServers : (envResources, type) => {
        let action = "resize";
        let payload = {
            instanceIds : envResources.map(resource => resource.id),
            instanceType: type
        }

        let payloadString = JSON.stringify(payload);
        console.log("Payload string", payloadString)
        let serverDomain = LabService.getServerDomain();
        fetch(serverDomain + "/admin/" + action, {
            method: "POST",
            headers: {
                "Content-Type" : "application/json",
                "Accept": "*/*",
                "Authorization" : AuthenticationService.getAuthorizationHeaderValue()
            },
            mode: "cors",
            credentials: "include",
            cache: "no-cache",
            body: payloadString
        }).then(response => response.json())
            .then(data => console.log(data));

    }

};

export default LabService;
