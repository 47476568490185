import Environment from "../Environment/Environment";
import {useEffect, useState} from "react";
import LabService from "../../Services/LabService";

const Environments = (props) => {

    const [resources, setResources] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {

        const successHandler = (data) => {
            setResources(data);
        }

        const errorHandler = (error) => {
            setError(error)
        };

        LabService.listServers(successHandler, errorHandler)
    }, []);


    return <div className={"Environments"}>

        <Environment displayName={"Dev"} tagName={"Development"} resources={resources}
                     showActions={true}
        />
        <Environment displayName={"Demo"} tagName={"Demo"} resources={resources}
                     showActions={true}
        />
        <Environment displayName={"Lab"} tagName={"Lab"} resources={resources}
        />
        <Environment displayName={"Other"}
                     excludedTagNames={["Development", "Demo", "Lab"]}
                     resources={resources}
                     showActions={false}
        />
    </div>;

}

export default Environments;
