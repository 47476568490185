import "./Links.css";
import AuthenticationService from "../../Services/AuthenticationService";

const Links = (props) => {


    console.log(AuthenticationService.keycloakState);

    return <div className={"Links"}>
        <h1 className={""}>
            Links
        </h1>
        <ul>
            <li><a target="_blank" href={"/dashboard/home"}>CosentiosAR Dashboard (Demo)</a></li>
            <li>Reference Implementation Data Admin Page (Demo)
                <ul>
                    <li><a target="_blank" href={"/admin/add.html"}>Add</a></li>
                    <li><a target="_blank" href={"/admin/update.html"}>Update</a></li>
                    <li><a target="_blank" href={"/admin/delete.html"}>Delete</a></li>
                    <li><a target="_blank" href={"/admin/debug.html"}>Debug</a></li>
                </ul>
            </li>
            <li><a target="_blank" href={"https://dev.lab.cosentios.com/"}>CosentiosAR Dashboard (DEV)</a></li>
            <li>Reference Implementation Data Admin Page (DEV)
                <ul>
                    <li><a target="_blank" href={"https://dev.lab.cosentios.com/dev-admin/add.html"}>Add</a></li>
                    <li><a target="_blank" href={"https://dev.lab.cosentios.com/dev-admin/update.html"}>Update</a></li>
                    <li><a target="_blank" href={"https://dev.lab.cosentios.com/dev-admin/delete.html"}>Delete</a></li>
                    <li><a target="_blank" href={"https://dev.lab.cosentios.com/dev-admin/debug.html"}>Debug</a></li>
                </ul>
            </li>
        </ul>

    </div>;
}

export default Links;
