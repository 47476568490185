import "./Resource.css";

const Resource = (props) => {
    let resource = props.resource;


    const getTagValue = (key) => {
        let value = "N/A";
        if (resource.tags && resource.tags[key]) {
            value = resource.tags[key];
        }
        return value;
    };


    let name = getTagValue("Name")
    let environment = getTagValue("Environment");
    let description = getTagValue("Description");
    let project = getTagValue("Project");


    let displaySnippet = [];

    displaySnippet.push(<div key={"name"} className={"Resource-Data"}>
        {name}
    </div>);


    displaySnippet.push(<div key={"type"} className={"Resource-Data"}>
        {resource.type}
    </div>);
    displaySnippet.push(<div key={"status"} className={"Resource-Data"}>
        {resource.status}
    </div>);
    /**
     *  <div className={"Resource-Data"}>
     {resource.id}
     </div>
     <div className={"Resource-Data"}>
     {resource.status}
     </div>
     <div className={"Resource-Data"}>
     {environment}
     </div>
     <div className={"Resource-Data"}>
     {description}
     </div>
     <div className={"Resource-Data"}>
     {project}
     </div>
     */
    return <div  className={"Resource"}>
        {displaySnippet}
    </div>;
}

export default Resource;
