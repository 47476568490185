import './App.css';
import {Fragment, useEffect, useState} from "react";
import AuthenticationService from "./Services/AuthenticationService";
import Environments from "./Components/Environments/Environments";
import Links from "./Components/Links/Links";

function App() {

    const [state, setState] = useState({
        keycloakState: null,
        attemptedAuthentication: false
    });

    useEffect(() => {

        const handleAuthSuccessful = (value) => {
            AuthenticationService.keycloakState = value;
            setState({
                ...state,
                keycloakState: value,
                attemptedAuthentication: true
            });
        }
        const handleAuthFailure = (value) => {

            if (value) {
                setState({
                    ...state,
                    keycloakState: value,
                    attemptedAuthentication: true
                });
            } else {

                setState({
                    ...state,
                    keycloakState: null,
                    attemptedAuthentication: true
                });
            }
        }

        if (!state.keycloakState && !state.attemptedAuthentication) {
            AuthenticationService.init(handleAuthSuccessful, handleAuthFailure);
        }

    }, [])

    let name = "";
    let snippet = <Fragment />;
    if (state.keycloakState && state.keycloakState.tokenParsed) {
        name = state.keycloakState.tokenParsed.name;
        snippet = <Fragment>


            <div className={"App-Environments"}>
                <Environments />
            </div>
        </Fragment>
    }
    return (
        <div className="App">
            <div className={"App-UserName"}>
            {name}
            </div>
            <div className={"App-Links"}>
                <Links />
            </div>
            {snippet}
        </div>
    );
}

export default App;
