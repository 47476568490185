import Configuration from "../Configuration";
import Keycloak from "keycloak-js";

const AuthenticationService = {

    keycloakState : {},

    getAuthorizationHeaderValue() {

        return "Bearer " + AuthenticationService.keycloakState.token;
    },
    getKeycloakObject : () => {
        return Keycloak({
            url: Configuration.keycloakUrl,
            realm: Configuration.keycloakRealm,
            clientId: Configuration.keycloakClientId,
        });
    },

    init : async (authenticationSuccessfulHandler, authenticationFailedHandler) => {
        let keycloakObject = AuthenticationService.getKeycloakObject();
        let keycloakPromise = keycloakObject.init({});


        keycloakPromise.then(async authenticated => {
            if (authenticated) {
                authenticationSuccessfulHandler(keycloakObject);
            } else {
                authenticationFailedHandler(keycloakObject);
            }
        })

        keycloakPromise.catch(authenticationFailedHandler);

        let result = await keycloakPromise;
        console.log(result);


        if (!keycloakObject.authenticated) {
            if (keycloakObject) {
                keycloakObject.login({
                    redirectUri: window.location.href
                });
            } else {
                console.log("Not authenticated and cannot login");
            }
        }
    }

};

export default AuthenticationService;
