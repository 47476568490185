import Resource from "../Resource/Resource";
import {Fragment} from "react";
import "./Environment.css";
import LabService from "../../Services/LabService";

const Environment = (props) => {


    let envResources = props.resources;

    const sortByTagName = (a, b) => a.tags["Name"] > b.tags["Name"];
    if (props.tagName && envResources) {
        envResources = envResources.filter(resource => resource.tags)
            .filter(resource => resource.tags["Environment"] === props.tagName)
        ;
    } else if (props.excludedTagNames && envResources) {
        envResources = envResources
            .filter(resource => resource.tags)
            .filter(resource => {
                let noMatches = true;
                for (let index = 0; index < props.excludedTagNames.length; index++) {
                    let tagName = props.excludedTagNames[index];
                    if (resource.tags["Environment"] === tagName) {
                        noMatches = false;
                        break;
                    }
                }

                return noMatches
            });
    }

    const getTable = (resources) => {
        let returnValue = <Fragment />;
        if (resources && resources.length > 0) {
            returnValue = resources.map((resource, index) => <Resource key={index} resource={resource}></Resource>)
        }
        return returnValue;
    }

    const handleStopClicked = () => {
        LabService.stopStartServers(true, envResources);
    }

    const handleStartClicked = () => {
        LabService.stopStartServers(false, envResources);
    }


    let singleUserInstanceType = {
        name: "c4.xlarge",
        vcpu: 4,
        ram: 7.5,
        cost: 0.20
    };
    let multiUserInstanceType = {
        name: "c4.8xlarge",
        vcpu: 36,
        ram: 60,
        cost: 2
    };
    // let largerMultiUserInstanceType = {
    //     name: "c5d.18xlarge",
    //     vcpu: 144,
    //     ram: 72,
    //     cost: 3
    // };

    const isAnyResourceNotStopped = () => {
        return envResources.filter(resource => resource.status.toLowerCase() !== "stopped").length > 0;
    }
    const handleResizeToMultiUser = () => {
        if (isAnyResourceNotStopped()) {
            alert(`Stop all instances first. Cannot change instance type to ${multiUserInstanceType.name} since all the instances in the ${props.displayName} environment must be stopped first.`)
            return;
        }
        LabService.resizeServers(envResources, multiUserInstanceType.name);
    }
    // const handleResizeToLargerMultiUser = () => {
    //     if (isAnyResourceNotStopped()) {
    //         alert(`Stop all instances first. Cannot change instance type to ${largerMultiUserInstanceType.name} since all the instances in the ${props.displayName} environment must be stopped first.`)
    //         return;
    //     }
    //     LabService.resizeServers(envResources, largerMultiUserInstanceType.name);
    // }
    const handleResizeToSingleUser = () => {
        if (isAnyResourceNotStopped()) {
            alert(`Stop all instances first. Cannot change instance type to ${singleUserInstanceType.name} since all the instances in the ${props.displayName} environment must be stopped first.`)
            return;
        }
        LabService.resizeServers(envResources, singleUserInstanceType.name);
    }

    let envResourcesTable = getTable(envResources);
    let headerSnippet = <Fragment />;
    let actionsSnippet = <Fragment />;
    if (props.showActions) {
        actionsSnippet = <div className={"Environment-Header-Bar-Actions"}>
            <div className={"Environment-Action"}>
                <button onClick={handleStopClicked}>Stop</button>
            </div>
            <div className={"Environment-Action"}>
                <button onClick={handleStartClicked}>Start</button>

            </div>
            <div className={"Environment-Action"}>
                <button onClick={handleResizeToSingleUser}>Single User ({singleUserInstanceType.name} / {singleUserInstanceType.vcpu} VCPU / {singleUserInstanceType.ram}GB RAM / ~${singleUserInstanceType.cost}/hr)</button>
            </div>
            <div className={"Environment-Action"}>
                <button onClick={handleResizeToMultiUser}>Multi User ({multiUserInstanceType.name} / {multiUserInstanceType.vcpu} VCPU / {multiUserInstanceType.ram}GB RAM / ~${multiUserInstanceType.cost}/hr)</button>
            </div>

            {/*<div className={"Environment-Action"}>*/}
            {/*    <button onClick={handleResizeToLargerMultiUser}>Larger Multi User ({largerMultiUserInstanceType.name} / {largerMultiUserInstanceType.vcpu} VCPU / {largerMultiUserInstanceType.ram}GB RAM / ~${largerMultiUserInstanceType.cost}/hr)</button>*/}

            {/*</div>*/}

        </div>;
    }
    if (envResources && envResources.length > 0) {
        headerSnippet = <div className={"Environment-Header-Bar"}>
            <h1>
                {props.displayName}
            </h1>
            {actionsSnippet}
        </div>;
    }

    return <div>

        {headerSnippet}
        {envResourcesTable}
    </div>
}
export default Environment;
